<template>
  <div class="news">
    <img class="news__image" :src="header" />
    <div class="news__info">
      <div class="news__title">{{ title }}</div>
      <div class="news__read-more">
        <router-link :to="{ name: 'Page Details', params: { id: id } }">
          {{ $gettext('Leggi tutto') }}
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import '@/api/index.js';
export default {
  name: 'NewsCard',
  props: ['title', 'header', 'id'],

  methods: {
    getImage() {
      return this.axios.get(this.api.getMediaUrl + this.id).then((res) => {});
    },
  },
};
</script>

<style scoped>
.news {
  margin-bottom: 30px;
  box-shadow: 0 0 16px rgba(0, 0, 0, 0.2);
  border-radius: 16px;
}

.news__info {
  padding: 30px;
}

.news__image {
  width: 100%;
  border-radius: 16px;
}

.news__title {
  font-size: 1.5rem;
  color: #333;
  display: block;
  text-align: left;
  font-weight: 600;
}

.news__read-more {
  display: block;
  margin-top: 30px;
  font-size: 1rem;
  font-weight: 700;
  text-align: left;
}

.news__read-more a {
  padding: 5px 0;
  text-decoration: none;
  text-transform: uppercase;
  letter-spacing: 4px;
  text-align: left;
  border-top: 2px solid #4f04cf;
  border-bottom: 2px solid #cf19b1;
  background: #4f04cf;
  background: -webkit-linear-gradient(to right, #4f04cf 0%, #cf19b1 100%);
  background: -moz-linear-gradient(to right, #4f04cf 0%, #cf19b1 100%);
  background: linear-gradient(to right, #4f04cf 0%, #cf19b1 100%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>
